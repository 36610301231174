//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CategoryCard',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    catalogClicked() {
      let query = {}
      if (this.category.hasChild) {
        query = {
          parentID: this.category.id,
        }
      }
      this.$router.push({
        path: `${
          this.category.hasChild ? '' : `/products/${this.category.code}`
        }`,
        query,
      })
    },
  },
}
