//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapState} from 'vuex'
import CategoryCard from '@/frontend/components/common/CategoryCard.vue'

export default {
  name: 'Home',
  components: {CategoryCard},
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    productCategories: [],
  }),
  async fetch() {
    await this.getCategoriesFromQuery()
    if(this.$route.query.userID && this.$route.query.userID !== ''){
      try {
        const uniqueId = this.$route.query.userID;
        const response = await this.$auth.loginWith('loginWithUID', {
          data: {
            uniqueId: uniqueId
          },
        })
        if(response.status === 200 && response.data.token){
          await this.$router.push('/catalog')
        }
      }catch (e){
        console.error("Error while logging in external user:", e)
        //the error handling will be implemented later because we don't know what they want to do if the login failed
      }
    }
  },
  computed: {
    ...mapState('backoffice/catalog', ['categories']),
  },
  watch: {
    async '$route.query'() {
      await this.getCategoriesFromQuery()
    },
  },
  methods: {
    ...mapActions('backoffice/catalog', [
      'getCategories',
      'getChildCategories',
    ]),
    async getCategoriesFromQuery() {
      if (this.$route.query.parentID && this.$route.query.parentID > 0) {
        return await this.getChildCategories(this.$route.query.parentID)
      } else {
        return await this.getCategories()
      }
    },
  }
}
